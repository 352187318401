import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, distinctUntilChanged, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private userDataSubject = new BehaviorSubject<any>(null);
  private programDataSubject = new BehaviorSubject<any>(null);
  private programSampleSTypeSubject = new BehaviorSubject<any>(null);
  private programSampleSubject = new BehaviorSubject<any>(null);
  private licenseAgreementFormProcess = new BehaviorSubject<any>(null);
  private programSampleTestResultSubject = new BehaviorSubject<any>(null);
  private programByIDData = new BehaviorSubject<any>(null);
  private programSampleTest = new BehaviorSubject<any>(null);
  private programLicenseLocalStorageKey = 'fq-program-license';
  private programLocalStorageKey = 'fq-program';
  private programSampleLocalStorageKey = 'fq-program-sampleType';
  private programSampleManagementLocalStorageKey = 'fq-program-sample';
  private licenseAgreementFormProcessLocalStorageKey =
    'fq-program-license-agreement-process';

  private sampleTestResultStorageKey = 'fq-program-sample-test-result';
  private programSampleTestLocalStorageKey = 'fq-program-sample-test';
  constructor() {
    const userProgramLIcense = localStorage.getItem(
      this.programLicenseLocalStorageKey
    );
    const storedData = localStorage.getItem(this.programLocalStorageKey);
    const storedSampleData = localStorage.getItem(
      this.programSampleLocalStorageKey
    );

    const storedLicenseAgreementFormProcessData = localStorage.getItem(
      this.licenseAgreementFormProcessLocalStorageKey
    );

    const storedProgramSampleManagementLocalStorageKey = localStorage.getItem(
      this.programSampleManagementLocalStorageKey
    );

    const sampleTestResultStorageData = localStorage.getItem(
      this.sampleTestResultStorageKey
    );
    if (userProgramLIcense) {
      this.userDataSubject.next(JSON.parse(userProgramLIcense));
    }
    if (storedData) {
      this.programByIDData.next(JSON.parse(storedData));
    }
    if (storedSampleData) {
      this.programSampleSubject.next(JSON.parse(storedSampleData));
    }
    if (storedSampleData) {
      this.programSampleSTypeSubject.next(JSON.parse(storedSampleData));
    }

    if (storedLicenseAgreementFormProcessData) {
      this.licenseAgreementFormProcess.next(
        JSON.parse(storedLicenseAgreementFormProcessData)
      );
    }

    if (storedProgramSampleManagementLocalStorageKey) {
      this.programSampleSubject.next(
        JSON.parse(storedProgramSampleManagementLocalStorageKey)
      );
    }

    if (sampleTestResultStorageData) {
      this.programSampleTestResultSubject.next(
        JSON.parse(sampleTestResultStorageData)
      );
    }
    const programBrandStorageData = localStorage.getItem(this.brandStorageKey);
    if (programBrandStorageData) {
      this.programBrandSubject.next(JSON.parse(programBrandStorageData));
    }
    const sampleTestStorageData = localStorage.getItem(
      this.programSampleTestLocalStorageKey
    );
    if (sampleTestStorageData) {
      this.programSampleTest.next(JSON.parse(sampleTestStorageData));
    }
  }
  setUserData(data: any) {
    this.userDataSubject.next(data);
    localStorage.setItem(
      this.programLicenseLocalStorageKey,
      JSON.stringify(data)
    );
  }

  getUserData() {
    return this.userDataSubject.asObservable();
  }

  setProgramByIDData(data: any) {
    this.programByIDData.next(data);
    localStorage.setItem(this.programLocalStorageKey, JSON.stringify(data));
  }

  getProgramByIdData() {
    return this.programByIDData.asObservable();
  }

  setProgramData(data: any) {
    this.programDataSubject.next(data);
  }

  getProgramData() {
    return this.programDataSubject.asObservable();
  }

  setProgramSampleType(data: any) {
    this.programSampleSTypeSubject.next(data);
    localStorage.setItem(
      this.programSampleLocalStorageKey,
      JSON.stringify(data)
    );
  }
  getProgramSampleType() {
    return this.programSampleSTypeSubject.asObservable();
  }

  //admin program details
  setProgramSampleManagement(data: any) {
    this.programSampleSubject.next(data);
    localStorage.setItem(
      this.programSampleManagementLocalStorageKey,
      JSON.stringify(data)
    );
  }
  getSetProgramSampleManagement() {
    return this.programSampleSubject.asObservable();
  }
  private refreshProgramCart = new BehaviorSubject<boolean>(false);

  getProgramCartObservable() {
    return this.refreshProgramCart.asObservable();
  }

  triggerProgramCartRefresh() {
    this.refreshProgramCart.next(true);
  }

  private refreshProgram = new BehaviorSubject<boolean | null>(null);

  getProgramObservable() {
    return this.refreshProgram.asObservable();
  }

  triggerProgramRefresh(ev: boolean) {
    this.refreshProgram.next(ev);
  }

  private refreshProgramDashboard = new BehaviorSubject<boolean>(false);

  getProgramDashboardObservable() {
    return this.refreshProgramDashboard.asObservable();
  }
  triggerProgramDashboardRefresh(ev: boolean) {
    this.refreshProgramDashboard.next(ev);
  }

  getLicenseAgreementFormProcessObservable() {
    return this.licenseAgreementFormProcess.asObservable();
  }

  setLicenseAgreementFormProcess(data: any) {
    this.licenseAgreementFormProcess.next(data);
    localStorage.setItem(
      this.licenseAgreementFormProcessLocalStorageKey,
      JSON.stringify(data)
    );
  }

  //admin program setProgramSampleTestResult details
  setProgramSampleTestResult(data: any) {
    this.programSampleTestResultSubject.next(data);
    localStorage.setItem(this.sampleTestResultStorageKey, JSON.stringify(data));
  }
  getProgramSampleTestResult() {
    return this.programSampleTestResultSubject.asObservable();
  }

  private programBrandSubject = new BehaviorSubject<any>(null);
  private brandStorageKey = 'fq-program-brand';
  setProgramBrand(data: any) {
    this.programBrandSubject.next(data);
    localStorage.setItem(this.brandStorageKey, JSON.stringify(data));
  }
  getProgramBrand() {
    return this.programBrandSubject.asObservable();
  }
  getSampleTestData() {
    return this.programSampleTest.asObservable();
  }
  setSampleTestData(data: any) {
    this.programSampleTest.next(data);
    localStorage.setItem(
      this.programSampleTestLocalStorageKey,
      JSON.stringify(data)
    );
  }

  private programSource: any = new BehaviorSubject(null);
  programListLocalStorageKey = 'fq-program-list-selected';

  public program$ = this.programSource
    .asObservable()
    .pipe(distinctUntilChanged());
  set program(data: any) {
    const programListSelected: any = localStorage.getItem(
      this.programListLocalStorageKey
    );
    if (programListSelected && programListSelected.id == data.id) {
      return;
    }
    localStorage.setItem('selectedProgram', data);
    this.programSource.next(data);
  }

  get program() {
    return this.programSource.value;
  }

  userProfileImage: any;
  getUserProfileData() {
    return this.userProfileImage;
  }
  setUserProfileData(data: any) {
    this.userProfileImage = data;
  }

  userProfileImageUrl: any;
  getUserProfileDataUrl() {
    return this.userProfileImageUrl;
  }
  setUserProfileDataUrl(data: any) {
    this.userProfileImageUrl = data;
  }

  private refreshProfileImage = new BehaviorSubject<boolean | null>(null);

  getUserProfileObservable() {
    return this.refreshProfileImage.asObservable();
  }

  triggerUserProfileRefresh(ev: boolean) {
    this.refreshProfileImage.next(ev);
  }
  private refreshProgramCartCount = new BehaviorSubject<boolean | null>(null);

  getProgramCartCountObservable() {
    return this.refreshProgramCartCount.asObservable();
  }

  triggerProgramCartCountRefresh() {
    this.refreshProgramCartCount.next(true);
  }
}
