import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';
import { NavigationLoaderService } from '../navigation/navigation-loader.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {

  constructor(
    private router: Router,
    private navigationLoaderService: NavigationLoaderService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const menuName = route.data['menuName'];
        return this.checkPermission(menuName);
      }
    

      
      // New function to check permission and optionally redirect
      checkPermission(menuName: string): boolean {
        const hasPermission = this.navigationLoaderService.isHavePermissionsCQA(menuName);
    
        if (!hasPermission) {
          this.router.navigate(['/access-denied']);
        }
        return hasPermission;
      }
    }