<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ "Quotation" }}
  </h2>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>

<mat-dialog-content>
  <mat-form-field subscriptSizing="dynamic" class="my-6 w-full">
    <mat-label>PO Number</mat-label>
    <input [formControl]="PONumber" matInput placeholder="Enter PO number" />
    <mat-error *ngIf="PONumber.hasError('required') && PONumber.touched">
      You must include a PO Number
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">Cancel</button>
  <button color="primary" mat-flat-button type="submit" (click)="onSubmit()">
    Submit
  </button>
</mat-dialog-actions>
