<pdf-shy-button
  title="Text"
  primaryToolbarId="primaryEditorStamp"
  [cssClass]="show | responsiveCSSClass : 'hiddenTinyView'"
  l10nId="pdfjs-editor-stamp-button"
  l10nLabel="pdfjs-editor-stamp-button-label"
  [order]="4200"
  [action]="onClick"
  [toggled]="isSelected"
  [closeOnClick]="true"
  image="<svg style='width: 20px; height: 20px' viewBox='0 0 24 24'> <path fill='currentColor' d='M13 19C13 19.7 13.13 20.37 13.35 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3H19C20.11 3 21 3.9 21 5V13.35C20.37 13.13 19.7 13 19 13V5H5V19H13M13.96 12.29L11.21 15.83L9.25 13.47L6.5 17H13.35C13.75 15.88 14.47 14.91 15.4 14.21L13.96 12.29M20 18V15H18V18H15V20H18V23H20V20H23V18H20Z' /> </svg>"
>
</pdf-shy-button>
<div
  class="editorParamsToolbar hidden doorHangerRight"
  id="editorStampParamsToolbar"
  [class]="show | responsiveCSSClass : 'hiddenTinyView'"
  *ngIf="pdfJsVersion >= '3.10'"
>
  <div class="editorParamsToolbarContainer">
    <button
      id="editorStampAddImage"
      class="secondaryToolbarButton"
      title="Add image"
      tabindex="105"
      data-l10n-id="pdfjs-editor-stamp-add-image-button"
      aria-label="Add image"
    >
      <svg
        role="img"
        aria-label="Add image"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="align-image-to-text"
      >
        <path
          d="M7.00488 9.75V14C7.00488 14.1658 7.07073 14.3247 7.18794 14.4419C7.30515 14.5592 7.46412 14.625 7.62988 14.625C7.79564 14.625 7.95461 14.5592 8.07183 14.4419C8.18904 14.3247 8.25488 14.1658 8.25488 14V9.75L8.75488 9.25H13.0049C13.1706 9.25 13.3296 9.18415 13.4468 9.06694C13.564 8.94973 13.6299 8.79076 13.6299 8.625C13.6299 8.45924 13.564 8.30027 13.4468 8.18306C13.3296 8.06585 13.1706 8 13.0049 8H8.75488L8.25488 7.5V3.25C8.25488 3.08424 8.18904 2.92527 8.07183 2.80806C7.95461 2.69085 7.79564 2.625 7.62988 2.625C7.46412 2.625 7.30515 2.69085 7.18794 2.80806C7.07073 2.92527 7.00488 3.08424 7.00488 3.25V7.5L6.50488 8H2.25488C2.08912 8 1.93015 8.06585 1.81294 8.18306C1.69573 8.30027 1.62988 8.45924 1.62988 8.625C1.62988 8.79076 1.69573 8.94973 1.81294 9.06694C1.93015 9.18415 2.08912 9.25 2.25488 9.25H6.39188L7.00488 9.75Z"
          fill="black"
        />
      </svg>
      <span data-l10n-id="pdfjs-editor-stamp-add-image-button-label">Add image</span>
    </button>
  </div>
</div>
