import { inject } from '@angular/core';
import { NavigationLoaderService } from './core/navigation/navigation-loader.service';
import { DataService } from './pages/home/program-license-agreement-process/data.service';
import { forkJoin } from 'rxjs';

export const initialDataResolver = () => {
  const programInfo = inject(NavigationLoaderService);
  // Fork join multiple API endpoint calls to wait all of them to finish
  return forkJoin([programInfo.get()]);
};
