<div
  class="sm:flex block my-3 justify-between items-center gap-4 overflow-auto">
  <div class="flex flex-row items-center gap-3 sm:flex-col md:flex-row">
    <mat-checkbox
      *ngIf="showCheckBox"
      color="primary"
      [(ngModel)]="isChecked"
      (change)="onCheckChange()">
    </mat-checkbox>
    <div class="w-full">
      <ng-container *ngIf="item.class == 'sample'">
        <h4 class="font-medium text-base">
          {{ item?.sampleType?.name }}
        </h4>
        <p class="text-sm text-gray-400">
          sample Id:{{ item?.sampleDetails?.sampleId }}
        </p>
        <p class="text-sm text-gray-400 break-words" *ngIf="showPO">
          PO Number:{{ PONumber ? PONumber : "-" }}
        </p>
      </ng-container>
      <ng-container *ngIf="item.class == 'brand'">
        <h4 class="font-medium text-base">{{ "Brands" }}</h4>
      </ng-container>

      <ng-container *ngIf="item.class == 'LicenseAgreement'">
        <h4 class="font-medium text-lg">{{ item?.name }}</h4>
        <p class="text-sm text-gray-400 break-words" *ngIf="showPO">
          PO Number:{{ PONumber ? PONumber : "-" }}
        </p>
      </ng-container>
    </div>
  </div>
  <div
    class="flex gap-4 sm:mt-0 mt-3 justify-between items-center"
    *ngIf="item.class == 'sample'">
    <div class="price flex flex-row justify-end items-end gap-2">
      <h6 class="text-base">
        {{ item.price | currency: "USD" : "symbol" : "1.0-0" }}
      </h6>
      <mat-icon
        *ngIf="showDelete"
        class="cursor-pointer text-gray-400"
        (click)="deleteCart(item)"
        svgIcon="mat:clear"></mat-icon>
    </div>
  </div>
  <div
    class="flex gap-4 sm:mt-0 mt-3 justify-between items-center"
    *ngIf="item.class == 'LicenseAgreement'">
    <div class="price flex flex-row justify-end items-end gap-2">
      <h6 class="text-base">
        {{ item.price | currency: "USD" : "symbol" : "1.0-0" }}
      </h6>
    </div>
  </div>
</div>
