import { Component, Inject } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'vex-add-po-number',
  standalone: true,
  imports: [
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    NgIf
  ],
  templateUrl: './add-po-number.component.html',
  styleUrls: ['./add-po-number.component.scss']
})
export class AddPoNumberComponent {
  PONumber = new FormControl('', Validators.required);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddPoNumberComponent>,
    
  ) {
    this.PONumber?.patchValue(data?.PONumber);

  }
  onSubmit() {
    if (this.PONumber.invalid) {
      this.PONumber.markAllAsTouched();
      return;
    }
    this.dialogRef.close({
      PONumber: this.PONumber.value
    });
  }
}
