export function parseResponse(resp: any): ResponseInterface {
    let error = resp?.error || {};

    let response: ResponseInterface = {
        status: true,
        message: resp?.error?.message,
        field: resp?.error?.fields,
        data: resp?.data || null,
    };

    if (resp?.token) {
        response.token = resp?.token;
    }

    if (error.code && error.code != '0') {
        response.status = false;
    }

    return response;
}

export interface ResponseInterface {
    status?: boolean;
    message?: string;
    data?: any;
    token?: string;
    field?: number;
}

export const regExpPattern = {
    email: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
    mobile: '^((\\+91-?)|0)?[0-9]{10}$',
    zip: '^[0-9]{5}(?:-[0-9]{4})?$',
    ssn: '^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$',
};
