import { Injectable } from '@angular/core';
import { AppSettingsService } from '../app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {
  role: any;
  path: string;
  userInfo: any;
  constructor(private appSettingsService: AppSettingsService) {
    this.userInfo = this.appSettingsService.userData().userDetails;
  }

  checkUser() {
    const role = this.appSettingsService.getUserRole();
    switch (role) {
      case 'ROLE_ADMIN':
        return true;
      case 'ROLE_ADMIN_STAFF':
        return true;
      case 'ROLE_SUB_ADMIN':
        return false;
      case 'ROLE_SUB_ADMIN_STAFF':
        return false;
      default:
        return false;
    }
  }

  checkUserGuest() {
    const role = this.appSettingsService.getUserRole();
    switch (role) {
      case null:
        return true;
      default:
        return false;
    }
  }

  checkUserCQAAdminStaff() {
    const role = this.appSettingsService.getUserRole();
    switch (role) {
      case 'ROLE_ADMIN_STAFF':
        return true;
      default:
        return false;
    }
  }

  checkUserCompanyAdminStaff() {
    const role = this.appSettingsService.getUserRole();
    switch (role) {
      case 'ROLE_SUB_ADMIN_STAFF':
        return true;
      default:
        return false;
    }
  }
}
