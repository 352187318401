import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/core/shared/shared.module';

@Component({
  selector: 'vex-terms-service',
  standalone: true,
  imports: [
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    CommonModule,
    MatButtonModule
  ],
  templateUrl: './terms-service.component.html',
  styleUrls: ['./terms-service.component.scss']
})
export class TermsServiceComponent {
  isChecked = false;
  cmsData: any;
  constructor(
    public dialogRef: MatDialogRef<TermsServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isChecked = data.isChecked;
    this.cmsData = data.data;
  }

  onSubmit() {
    this.dialogRef.close({ isChecked: true });
  }
}
