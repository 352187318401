import { EventEmitter, Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './cart.service';
import { firstValueFrom } from 'rxjs';
import { DataService } from 'src/app/pages/home/program-license-agreement-process/data.service';

@Injectable({
  providedIn: 'root'
})
export class CartManager {
  cartData: any;
  subTotal: number = 0.0;
  processingFee: number = 0.0;
  taxes: number = 0.0;
  total: number = 0.0;
  cartItemCount: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private toastr: ToastrService,
    private layoutService: VexLayoutService,
    private cartService: CartService,
    private dataService: DataService
  ) {}

  cartItems: any;
  // updated code
  async addCart(plan: any) {
    const cartDataString = localStorage.getItem('cart');
    this.cartItems = cartDataString
      ? JSON.parse(cartDataString)
      : {
          items: []
        };

    const existingItem = this.cartItems.items.find(
      (item: any) => item.name === plan.name
    );

    if (!existingItem) {
      this.cartItems.items.push({ ...plan, quantity: 1 });

      this.subTotal = this.cartItems?.items.reduce(
        (total: number, item: any) => total + item.price,
        0
      );
      this.total = this.processingFee + this.taxes + this.subTotal;
      this.cartItems = {
        ...this.cartItems,
        total: this.total,
        subTotal: this.subTotal,
        processingFee: this.processingFee,
        taxes: this.taxes
      };
      localStorage.setItem('cart', JSON.stringify(this.cartItems));
      this.toastr.success('Your order has been placed!');
      this.cartItemCount.emit();
    } else {
      const index = this.cartItems.items.findIndex(
        (item: any) => item.name === plan.name
      );

      console.log(index);
      console.log(this.cartItems);
      if (index !== -1) {
        // Use splice() to replace the item at the found index with a new object
        this.cartItems.items.splice(index, 1, { ...plan, quantity: 1 });
      }

      localStorage.setItem('cart', JSON.stringify(this.cartItems));

      this.toastr.success('File was submitted');
    }
    this.calculateTotal();
  }
  async calculateTotal() {
    if (
      this.cartItems &&
      this.cartItems?.items &&
      this.cartItems?.items.length > 0
    ) {
      this.subTotal = this.cartItems.items.reduce(
        (total: number, item: any) => total + item.price,
        0
      );
      this.total = this.processingFee + this.taxes + this.subTotal;
      console.log(this.cartItems);
      this.cartItems = {
        ...this.cartItems,
        total: this.total,
        subTotal: this.subTotal,
        processingFee: this.processingFee,
        taxes: this.taxes
      };
    }
  }

  deleteCart(item: any) {
    const index = this.cartItems.items.findIndex(
      (cartItem: any) => cartItem.name === item.name
    );

    if (index !== -1) {
      this.cartItems.items.splice(index, 1);
      this.subTotal = this.cartItems?.items.reduce(
        (total: number, item: any) => total + item.price,
        0
      );
      this.total = this.processingFee + this.taxes + this.subTotal;
      this.cartItems = {
        ...this.cartItems,
        total: this.total,
        subTotal: this.subTotal,
        processingFee: this.processingFee,
        taxes: this.taxes
      };
      localStorage.setItem('cart', JSON.stringify(this.cartItems));
      this.toastr.success('Product has been removed from the cart');
      this.calculateTotal();
      this.cartItemCount.emit();
    }
  }
  getBadgeLength() {
    return this.cartData?.cartItems?.length;
  }

  // async addCartItem(newCartItem: any): Promise<any> {
  //   try {
  //     let resCartData = await firstValueFrom(
  //       this.cartService.createCart(newCartItem)
  //     );
  //     this.toastr.success('Item Added to Cart');
  //     this.dataService.triggerProgramCartRefresh();
  //     return true;
  //   } catch (err: any) {
  //     throw new Error(err);
  //   }
  // }
  async addCartItem(newCartItem: any): Promise<boolean> {
    try {
      const resCartData = await firstValueFrom(
        this.cartService.createCart(newCartItem)
      );
      this.toastr.success('Item Added to Cart');
      this.dataService.triggerProgramCartRefresh();
      return true;
    } catch (err) {
      console.error('Error adding item to cart:', err);
      return false; // or handle the error appropriately
    }
  }
}
