<div *ngIf="loading" class="loader-overlay relative">
  <button
    class="text-secondary absolute top-0 right-0"
    mat-dialog-close
    mat-icon-button
    type="button"
    (click)="loading = false">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
  <div id="loader" class="text-center">
    <div class="loader mx-auto mb-4">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p class="text-white text-base">One moment please...</p>
  </div>
</div>
