<div class="flex items-center" mat-dialog-title>
  <!-- <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button> -->
</div>

<!-- <mat-divider class="text-border"></mat-divider> -->

<mat-dialog-content class="flex flex-col">
  <ng-container *ngIf="data.title || data.message">
    <div
      class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
      <!-- Title -->
      <ng-container *ngIf="data.title">
        <div
          class="text-xl leading-6 font-medium"
          [innerHTML]="data.title"></div>
      </ng-container>

      <!-- Message -->
      <ng-container *ngIf="data.message">
        <div class="text-secondary" [innerHTML]="data.message"></div>
      </ng-container>
    </div>
  </ng-container>
</mat-dialog-content>

<ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
  <mat-dialog-actions
    class="flex flex-row justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
    <div class="flex items-center justify-center sm:justify-end">
      <!-- Cancel -->
      <ng-container *ngIf="data.actions.cancel.show">
        <button class="cancel" mat-stroked-button [matDialogClose]="null">
          {{ data.actions.cancel.label }}
        </button>
      </ng-container>

      <!-- Confirm -->
      <ng-container *ngIf="data.actions.confirm.show">
        <button
          mat-flat-button
          [matDialogClose]="{ status: true }"
          (click)="onConfirm()"
          color="primary">
          {{ data.actions.confirm.label }}
        </button>
      </ng-container>
    </div>
  </mat-dialog-actions>
</ng-container>
