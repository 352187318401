import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild
} from '@angular/core';
import { SharedModule } from 'src/app/core/shared/shared.module';
import {
  NgxExtendedPdfViewerComponent,
  NgxExtendedPdfViewerModule,
  NgxExtendedPdfViewerService
} from 'src/app/pdf/ngx-extended-pdf-viewer';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/layouts/components/loader/loader.component';
import { firstValueFrom } from 'rxjs';
import { ProgramConfigService } from '../../program-config.service';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vex-view-pdf',
  standalone: true,
  imports: [
    NgxExtendedPdfViewerModule,
    LoaderComponent,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    NgIf,
    MatButtonModule
  ],
  providers: [NgxExtendedPdfViewerService],
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent {
  isLoading: boolean = true;
  src: any;
  title: string = 'View Form';
  pdfData: any;
  disableFiled: any;
  showOnSubmit: boolean = false;
  @ViewChild(NgxExtendedPdfViewerComponent, { static: false })
  private pdfViewer!: NgxExtendedPdfViewerComponent;
  isProcessing: boolean = false;
  constructor(
    private pdfService: NgxExtendedPdfViewerService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewPdfComponent>,
    private _elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private programConfigService: ProgramConfigService,
    private appSettingsService: AppSettingsService,
    private toastr: ToastrService
  ) {
    this.title = data?.title;
    this.pdfData = data?.loadPdfData;
    this.disableFiled = data?.disableFiled;
    dialogRef.beforeClosed().subscribe((result) => {
      this.pdfViewer.ngOnDestroy();
    });
    this.showOnSubmit = data?.loadPdfData?.showOnSubmit;
  }

  public formData: any = {};
  pdfHtml: any;
  async ngOnInit() {
    this.onUploadFormData();

    if (this.data?.loadPdfData) {
      this.src = await this.updatePdfFormFields(this.data.src);
    } else {
      this.src = this.data?.src;
    }
  }
  onUploadFormData() {
    if (this.data && this.data?.loadPdfData) {
      this.formData = {
        ...this.pdfData,
        ZipCode: this.pdfData?.CoPostalCode
      };
    }
  }

  onPageRendered(ev?: any) {
    setTimeout(async () => {
      if (this.data && this.data?.loadPdfData) {
        // this.formData = {
        //   ...this.pdfData,
        //   ZipCode: this.pdfData?.CoPostalCode
        // };
        // Loop through each key in pdfData and update the corresponding form element

        const LicenseFeeInput = this._elementRef.nativeElement.querySelector(
          `input[name="LicenseFee"], select[name="LicenseFee"], textarea[name="LicenseFee"]`
        );
        const LicenseRenewalFeeInput =
          this._elementRef.nativeElement.querySelector(
            `input[name="LicenseRenewalFee"], select[name="LicenseRenewalFee"], textarea[name="LicenseRenewalFee"]`
          );
        const SampleFeeInput = this._elementRef.nativeElement.querySelector(
          `input[name="SampleFee"], select[name="SampleFee"], textarea[name="SampleFee"]`
        );

        // Applying styles dynamically
        if (LicenseFeeInput) {
          LicenseFeeInput.style.fontFamily = "'ArialBold', Arial, sans-serif";
          LicenseFeeInput.style.fontWeight = 'bold';
        }

        if (LicenseRenewalFeeInput) {
          LicenseRenewalFeeInput.style.fontFamily =
            "'ArialBold', Arial, sans-serif";
          LicenseRenewalFeeInput.style.fontWeight = 'bold';
        }
        if (SampleFeeInput) {
          SampleFeeInput.style.fontFamily = "'ArialBold', Arial, sans-serif";
          SampleFeeInput.style.fontWeight = 'bold';
        }

        const inputElements = this._elementRef.nativeElement.querySelectorAll(
          'input, select, textarea'
        );

        // Loop through each element and disable it
        inputElements.forEach(
          (
            inputElement:
              | HTMLInputElement
              | HTMLSelectElement
              | HTMLTextAreaElement
          ) => {
            inputElement.style.fontFamily = 'arial';
            inputElement.disabled = true;
          }
        );

        this.cdr.detectChanges();
      }

      if (this.disableFiled) {
        // Loop through each key in pdfData and update the corresponding form element
        const inputElements = this._elementRef.nativeElement.querySelectorAll(
          'input, select, textarea'
        );

        // Loop through each element and disable it
        inputElements.forEach(
          (
            inputElement:
              | HTMLInputElement
              | HTMLSelectElement
              | HTMLTextAreaElement
          ) => {
            inputElement.disabled = true;
          }
        );

        this.setReadonlyFieldsContinuously();
      }
      this.cdr.detectChanges();
    }, 100);
  }
  signatureKeys = ['CQA02Signature', 'CQA03Signature'];
  // signature = this.signatureKeys.map((i:any)=>{
  //   return i
  // })
  @ViewChild('signatureInput') signatureInput: ElementRef;
  ngAfterViewInit() {
    // this.enableSignatureClick();
    this.onPageRendered();
  }
  enableSignatureClick() {
    this.signatureInput.nativeElement.addEventListener('click', () => {
      const editorButton = document.getElementById('primaryEditorInk');
      if (editorButton) {
        editorButton.click();
      }
    });
  }

  setReadonlyFieldsContinuously() {
    const observer = new MutationObserver(() => {
      const LicenseFeeInput = this._elementRef.nativeElement.querySelector(
        `input[name="LicenseFee"], select[name="LicenseFee"], textarea[name="LicenseFee"]`
      );
      const LicenseRenewalFeeInput =
        this._elementRef.nativeElement.querySelector(
          `input[name="LicenseRenewalFee"], select[name="LicenseRenewalFee"], textarea[name="LicenseRenewalFee"]`
        );

      if (LicenseFeeInput) {
        LicenseFeeInput.readOnly = true;
      }
      if (LicenseRenewalFeeInput) {
        LicenseRenewalFeeInput.readOnly = true;
      }

      // Trigger change detection to ensure the view is updated
      this.cdr.detectChanges();
    });

    // Observe changes in the child elements of the PDF container
    observer.observe(this._elementRef.nativeElement, {
      childList: true,
      subtree: true
    });
  }

  public blob: Blob | undefined;
  agreementFileName: string;
  public async export(): Promise<void> {
    this.isLoading = true;
    this.isProcessing = true;
    this.blob = await this.pdfService.getCurrentDocumentAsBlob();
    const fileName = 'IFC02.pdf';
    const formData = new FormData();
    formData.append('file', this.blob, fileName);

    let res = await firstValueFrom(
      this.programConfigService.postAttachment(formData)
    );
    this.agreementFileName = res.fileName;
  }

  async onSubmit() {
    await this.export();
    this.isProcessing = false;
    this.isLoading = false;
    this.dialogRef.close({
      submit: true,
      agreementFileName: this.agreementFileName // Pass the file name
    });
  }

  public async updatePdfFormFields(pdfUrl: string): Promise<void> {
    try {
      if (this.data && this.data?.loadPdfData) {
        this.formData = {
          ...this.pdfData
        };
      }
      let data = await this.appSettingsService.updatePdfFormFields(
        pdfUrl,
        this.formData
      );
      return data;
    } catch (error) {
      console.error('Error updating PDF form fields:', error);
    } finally {
      this.isLoading = false;
      this.isProcessing = false;
      this.cdr.detectChanges();
    }
  }

  onLoadComplete(ev: any) {
    this.isLoading = false;
  }

  onPdfLoadError(error: any): void {
    console.error('Error loading PDF:', error);

    // Show error message to the user
    this.toastr.error('Failed to load the PDF. Please try again.');
  }
}
