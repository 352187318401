<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar text-default w-full px-6 flex items-center">
  <button
    (click)="openSidenav()"
    [class.hidden]="isDesktop$ | async"
    mat-icon-button
    type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 flex items-center">
    <img
      alt="Logo"
      class="w-16 select-none"
      src="assets/img/logo/cqa_logo_v2.svg" />
    <!-- <h1
      [class.hidden]="isDesktop$ | async"
      class="text-2xl font-bold tracking-wide ltr:pl-4 rtl:pr-4 m-0 select-none">
      {{ title$ | async }}
    </h1> -->
  </a>

  <div
    *ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)"
    [class.hidden]="!(isDesktop$ | async)"
    class="px-6 flex-none flex items-center">
    <vex-navigation-item
      *ngFor="let item of navigationItems$ | async"
      [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <!-- <div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div> -->
    <div
      class="mt-5"
      (click)="$event.stopPropagation()"
      *ngIf="this.showProgram">
      <mat-form-field class="w-64 sm:w-64 lg:w-64">
        <mat-select
          [compareWith]="compareObjects"
          panelClass="dropdown"
          [(ngModel)]="dataService.program"
          [ngModelOptions]="{ standalone: true }">
          <mat-option *ngFor="let item of programList$ | async" [value]="item">
            <div>
              <span>{{ item?.name }}</span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="px-1" *ngIf="checkUser.checkUser()">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>
    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <button
      mat-icon-button
      *ngIf="showCart"
      color="primary"
      (click)="openCart()"
      class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 bg-primary-600/10 cursor-pointer">
      <mat-icon
        [matBadge]="_cartItemCount"
        [svgIcon]="'mat:shopping_cart'"
        aria-hidden="false"></mat-icon>
    </button>

    <!-- <div (click)="drawer.toggle()"
      class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 cursor-pointer">
      <mat-icon svgIcon="mat:shopping_cart"></mat-icon>
    </div> -->

    <!-- <div mat-icon-button (click)="isExpanded = !isExpanded"
      class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 cursor-pointer">
      <mat-icon svgIcon="mat:shopping_cart"></mat-icon>
    </div>
    <mat-sidenav-container class="example-container" autosize>
      <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true">
        <vex-toolbar-cart *ngIf="isExpanded || isShowing" [drawer]="drawer"></vex-toolbar-cart>
      </mat-sidenav>
    </mat-sidenav-container> -->
    <!-- <mat-drawer-container>
      <mat-drawer class="cart-layout" [autoFocus]="false" [position]="'end'" [mode]="'over'" [(opened)]="drawerOpened"
        #drawer>
        <ng-container>
          <vex-toolbar-cart [drawer]="drawer"></vex-toolbar-cart>
        </ng-container>
      </mat-drawer>
      <mat-drawer-content class="flex flex-col">
        <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 cursor-pointer">
          <mat-icon (click)="openCart()" svgIcon="mat:shopping_cart"></mat-icon>
        </div>
      </mat-drawer-content>
    </mat-drawer-container> -->
  </div>
</div>
<vex-navigation
  *ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
  [class.hidden]="!(isDesktop$ | async)"></vex-navigation>
