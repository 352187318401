import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { AppSettingsService } from 'src/app/core/app-settings.service';
import { parseResponse } from 'src/app/core/helpers';
import { Roles } from 'src/app/core/module/roles-interface';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class RolesService {
  constructor(
    private http: HttpClient,
    private appSettingsService: AppSettingsService
  ) {}

  getRoles(queryParams: any): Observable<any> {
    let params = this.appSettingsService.queryStrFormat(queryParams);

    return this.http.get('menu-role/list?' + params, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          let rows = resp.data.rows;
          for (const item of rows) {
            let temp: Roles = {
              ...item
            };
            retData.items.push(temp);
          }

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }
  private menuRoleCache: any = null;

  createRoles(data: Roles): Observable<any> {
    this.menuRoleCache = null;
    return this.http.post('menu-role/create', data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }
  getByRolesId(id: any): Observable<any> {
    // Check if the data is already cached
    // if (this.menuRoleCache) {
    //   return of(this.menuRoleCache); // Return cached data as an observable
    // }

    return this.http.get('menu-role/' + id).pipe(
      map((resp: any) => {
        let Roles: any;
        if (resp && resp.data) {
          let data = resp.data;
          Roles = data;
        }
        this.menuRoleCache = Roles;
        return Roles;
      })
    );
  }
  getByRolesIdEdit(id: any): Observable<any> {
    return this.http.get('menu-role/' + id).pipe(
      map((resp: any) => {
        let Roles: any;
        if (resp && resp.data) {
          let data = resp.data;
          Roles = data;
        }
        this.menuRoleCache = Roles;
        return Roles;
      })
    );
  }

  updateRoles(id: any, data: Roles): Observable<any> {
    this.menuRoleCache = null;
    return this.http.put('menu-role/' + id, data, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  deleteRoles(id: any): Observable<any> {
    return this.http.delete('menu-role/' + id, httpOptions).pipe(
      map((resp) => {
        return parseResponse(resp);
      })
    );
  }

  //handle error messages
  handleError(err: any) {
    this.appSettingsService.handleError(err);
  }

  getAllRoles(params: any): Observable<any> {
    let param = this.appSettingsService.queryStringFormatWithoutOffset(params);
    return this.http.get('menu-role/list?' + param, httpOptions).pipe(
      map((resp: any) => {
        let retData: any = {
          items: [],
          totalCount: 0,
          error: ''
        };

        if (
          resp &&
          resp.data &&
          Array.isArray(resp.data.rows) &&
          resp?.error?.code <= 0
        ) {
          retData.items = resp.data.rows.map((item: any) => ({ ...item }));
          // Assuming resp.data contains the JSON data
          // retData.items = resp.data.rows.map((permission: any) => ({
          //   id: parseInt(permission.id),
          //   name: resp.data.name,
          //   menuID: parseInt(permission?.menuID),
          //   menuActionID: parseInt(permission?.menuActionId),
          //   menuAction: {
          //     id: parseInt(permission.menuAction?.id),
          //     name: permission.menuAction?.name,
          //     isDelete: permission.menuAction?.isDelete
          //   }
          // }));

          retData.totalCount = resp.data.count;
        } else {
          retData.error = resp?.error?.message ? resp.error.message : '';
        }

        return retData;
      })
    );
  }
}
