import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appCurrencyFormatRounded]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoundedUSDFormat),
      multi: true
    }
  ],
  exportAs: 'appCurrencyFormatRounded',
  standalone: true
})
export class RoundedUSDFormat implements ControlValueAccessor {
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    this.formatCurrency(event.target.value);
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    this.formatCurrency(event.target.value, true);
    this.onTouched(); // Mark the control as touched
  }

  writeValue(value: any): void {
    if (value) {
      this.el.nativeElement.value = this.formatNumber(value);
    } else {
      this.el.nativeElement.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private formatNumber(n: string): string {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private formatCurrency(inputValue: string, blur?: boolean): void {
    let input_val = inputValue;
    if (input_val === '') {
      this.onChange(null); // Emit null for empty input
      return;
    }

    const original_len = input_val.length;
    const caret_pos = this.el.nativeElement.selectionStart;

    if (input_val.indexOf('.') >= 0) {
      const decimal_pos = input_val.indexOf('.');
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos + 1);

      left_side = this.formatNumber(left_side);
      right_side = this.formatNumber(right_side);

      if (blur === true) {
        while (right_side.length < 2) {
          right_side += '0';
        }
      }

      right_side = right_side.substring(0, 2);
      input_val = left_side + '.' + right_side;
    } else {
      input_val = this.formatNumber(input_val);

      // if (blur === true) {
      //   input_val += '.00';
      // }
    }

    this.el.nativeElement.value = input_val;
    this.onChange(input_val); // Emit the formatted value
    const updated_len = input_val.length;
    const new_caret_pos = updated_len - original_len + caret_pos;
    this.el.nativeElement.setSelectionRange(new_caret_pos, new_caret_pos);
  }
}
