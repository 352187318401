<!-- <div
  class="sm:flex block my-3 justify-between items-center gap-4 overflow-auto cart-item">
  <div class="flex flex-row items-center gap-3 sm:flex-col md:flex-row">
    <mat-checkbox
      *ngIf="showCheckBox"
      color="primary"
      [(ngModel)]="isChecked"
      (change)="onCheckChange()">
    </mat-checkbox>
    <div class="flex flex-col justify-start items-start gap-3">
      <ng-container *ngIf="item.class == 'sample'">
        <h4 class="font-base text-base">
          {{ item?.sampleType?.name }}
        </h4>
        <p class="text-xs text-gray-400">
          Company Sample ID: {{ item?.sampleDetails?.CoSampleID || "-" }}<br />
          CQA Sample ID: {{ item?.sampleDetails?.CQAId || "-" }}
        </p>
        <br />

        <p class="text-sm text-gray-400 break-words" *ngIf="showPO">
          PO Number:{{ PONumber ? PONumber : "-" }}
        </p>
      </ng-container>
      <ng-container *ngIf="item.class == 'brand'">
        <h4 class="font-base text-base">{{ "Brands" }}</h4>
      </ng-container>

      <ng-container *ngIf="item.class == 'LicenseAgreement'">
        <h4 class="text-base">{{ item?.name }}</h4>
        <p class="text-sm text-gray-400 break-words" *ngIf="showPO">
          PO Number:{{ PONumber ? PONumber : "-" }}
        </p>
        <h4 class="text-base" *ngIf="item.isDueAgreement">
          Late Payment Finance Charge
        </h4>
      </ng-container>
    </div>
  </div>
  <div
    class="flex gap-4 sm:mt-0 mt-3 justify-between items-center"
    *ngIf="item.class == 'sample'">
    <div class="price flex flex-row justify-end items-end gap-3">
      <h6 class="text-base">
        {{ item.price | currency: "USD" : "symbol" : "1.0-0" }}
      </h6>
      <mat-icon
        *ngIf="showDelete"
        class="cursor-pointer text-gray-400"
        (click)="deleteCart(item)"
        svgIcon="mat:clear"></mat-icon>
    </div>
  </div>
  <div
    class="flex flex-col gap-4 sm:mt-0 mt-3 items-center"
    *ngIf="item.class == 'LicenseAgreement'">
    <div class="price flex flex-col justify-end items-end gap-3">
      <h6 class="text-base">
        {{ item.price | currency: "USD" : "symbol" : "1.0-0" }}
      </h6>
      <h5 class="text-base" *ngIf="item.isDueAgreement">
        {{ item.latePaymentFee | currency: "USD" : "symbol" : "1.0-0" }}
      </h5>
    </div>
  </div>
</div> -->
<table class="table-auto w-full my-3">
  <tr class="border-b" *ngIf="showCheckBox">
    <td class="p-4" colspan="2">
      <mat-checkbox
        *ngIf="showCheckBox"
        color="primary"
        [(ngModel)]="isChecked"
        (change)="onCheckChange()">
      </mat-checkbox>
    </td>
  </tr>

  <tr *ngIf="item.class == 'sample'">
    <td class="p-2 align-top">
      <h4 class="font-base text-base">
        {{ item?.sampleType?.name }}
      </h4>
      <p class="text-xs text-gray-400">
        Company Sample ID: {{ item?.sampleDetails?.CoSampleID || "-" }}<br />
        CQA Sample ID: {{ item?.sampleDetails?.CQAId || "-" }}
      </p>
      <br />
      <p class="text-sm text-gray-400 break-words" *ngIf="showPO">
        PO Number:{{ PONumber ? PONumber : "-" }}
      </p>
    </td>
    <td class="p-2 align-top">
      <div class="price flex flex-col justify-end items-end gap-3">
        <h6 class="text-base">
          {{ item.price | currency: "USD" : "symbol" : "1.0-0" }}
        </h6>
        <mat-icon
          *ngIf="showDelete"
          class="cursor-pointer text-gray-400"
          (click)="deleteCart(item)"
          svgIcon="mat:clear"></mat-icon>
      </div>
    </td>
  </tr>

  <tr *ngIf="item.class == 'brand'">
    <td class="p-4">
      <h4 class="font-base text-base">{{ "Brands" }}</h4>
    </td>
  </tr>

  <tr *ngIf="item.class == 'LicenseAgreement'">
    <td class="p-2 align-top">
      <div class="description flex flex-col justify-start items-start gap-3">
        <h4 class="text-base text-start">{{ item?.name }}</h4>
        <p class="text-sm text-start text-gray-400 break-words" *ngIf="showPO">
          PO Number:{{ PONumber ? PONumber : "-" }}
        </p>
        <h4 class="text-base text-start" *ngIf="item.isDueAgreement">
          Late Payment Finance Charge
        </h4>
      </div>
    </td>
    <td class="p-2 align-top">
      <div class="price flex flex-col justify-end items-end gap-3">
        <h6 class="text-base">
          {{ item.price | currency: "USD" : "symbol" : "1.0-0" }}
        </h6>
        <div *ngIf="showPO"></div>
        <h5 class="text-base" *ngIf="item.isDueAgreement">
          {{ item.latePaymentFee | currency: "USD" : "symbol" : "1.0-0" }}
        </h5>
      </div>
    </td>
  </tr>
</table>
